<template>
  <section
    id="about"
    class="overlap-height position-relative background-position-center-top md-pt-50px sm-pt-20px overflow-hidden"
    style="
      background-image: url('assets/images/vertical-line-bg-medium-gray.svg');
    "
  >
    <div class="container overlap-gap-section position-relative">
      <div
        class="position-absolute top-50px right-minus-50px lg-right-minus-20px d-none d-md-inline-block"
      >
        <img
          src="@/assets/images/demo-ebook-shape.webp"
          data-bottom-top="transform: rotate(-10deg) translateY(50px)"
          data-top-bottom="transform:rotate(10deg) translateY(-50px)"
          alt=""
        />
      </div>
      <div class="position-absolute bottom-40px md-bottom-60px left-minus-50px">
        <img
          src="@/assets/images/demo-ebook-shape-02.webp"
          data-bottom-top="transform: rotate(10deg) translateX(50px)"
          data-top-bottom="transform:rotate(-10deg) translateX(-50px)"
          alt=""
        />
      </div>
      <div class="row mb-3">
        <div
          class="col-12 text-center"
          data-anime='{ "el": "childs", "translateY": [30, 0], "opacity": [0,1], "duration": 600, "delay": 0, "staggervalue": 300, "easing": "easeOutQuad" }'
        >
          <span class="d-block text-uppercase mb-5px text-base-color fw-500"
            >baixe nossos catalogos e
          </span>
          <h2
            class="fw-500 ls-minus-2px alt-font text-dark-gray text-uppercase"
          >
            conheça toda nossa linha de produtos
          </h2>
        </div>
      </div>
      <div
        class="row row-cols-1 row-cols-lg-4 row-cols-md-2 justify-content-center mb-5 md-mb-7"
        data-anime='{ "el": "childs", "rotateZ": [5, 0], "translateY": [50, 0], "opacity": [0,1], "duration": 600, "delay": 0, "staggervalue": 300, "easing": "easeOutQuad" }'
      >
        <!-- start features box item -->
        <div
          class="col icon-with-text-style-10 md-mb-30px"
          data-bottom-top="transform: translateY(20px)"
          data-top-bottom="transform: translateY(-20px)"
        >
          <a href="https://1drv.ms/b/s!AjgEqWPx1haLgRhgvOVJpS0OhoJ7?e=1UoMhC" target="_blank">
            <img
              src="@/assets/Capas de Catalogo + Páginas/Catalogo de Festas (1).jpg"
              alt=""
            />
          </a>
        </div>
        <!-- end features box item -->
        <!-- start features box item -->
        <div
          class="col icon-with-text-style-10 md-mb-30px"
          data-bottom-top="transform: translateY(20px)"
          data-top-bottom="transform: translateY(-20px)"
        >
        <a href="https://1drv.ms/b/s!AjgEqWPx1haLgUjNZQRh0f6sWRW-?e=DVWZoy" target="_blank">
          <img
            src="@/assets/Capas de Catalogo + Páginas/Embalagens Gerais (2).jpg"
            alt=""
          />
          </a>
          
        </div>
        <!-- end features box item -->
        <!-- start features box item -->
        <div
          class="col icon-with-text-style-10 md-mb-30px"
          data-bottom-top="transform: translateY(20px)"
          data-top-bottom="transform: translateY(-20px)"
        >
        <a href="https://1drv.ms/b/s!AjgEqWPx1haLgRUx3Oh5LlLMM589?e=hytJUH" target="_blank">
          <img
            src="@/assets/Capas de Catalogo + Páginas/Natal (1).jpg"
            alt=""
          />
        </a>
          
        </div>
        <!-- end features box item -->
        <!-- start features box item -->
        <div
          class="col icon-with-text-style-10 md-mb-30px"
          data-bottom-top="transform: translateY(20px)"
          data-top-bottom="transform: translateY(-20px)"
        >
        <a href="https://1drv.ms/b/s!AjgEqWPx1haLgQza7XcMpglZArKf?e=Zhf6xN" target="_blank">
          <img
            src="@/assets/Capas de Catalogo + Páginas/Páscoa (1).jpg"
            alt=""
          />
        </a>
          
        </div>
        <!-- end features box item -->
      </div>

      <div
        class="row justify-content-center position-relative"
        data-anime='{ "el": "childs", "translateY": [50, 0], "opacity": [0,1], "duration": 600, "delay": 0, "staggervalue": 300, "easing": "easeOutQuad" }'
      >
        <!-- <div class="col-auto text-center last-paragraph-no-margin">
          <div class="d-inline-block align-middle me-15px sm-m-5px">
            <img src="../../assets/images/demo-ebook-01.jpg" alt="" />
          </div>
          <div
            class="d-inline-block text-dark-gray fs-24 align-middle ls-minus-05px"
          >
            Lorem ipsum dolor sit amet consectetur
            adipisicing ElementInternals.
          </div>
        </div> -->
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'AppSection2',
  data() {
    return {};
  },
};
</script>

<style></style>
