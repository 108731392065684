<template>
  <footer class="footer-dark bg-dark-gray pt-1 pb-2 lg-pb-35px">
    <div class="container">
      <div
        class="row justify-content-center fs-17 fw-300 mt-5 mb-4 md-mt-45px md-mb-45px xs-mt-35px xs-mb-35px"
      >
        <!-- start footer column -->
        <div
          class="col-6 col-lg-4 order-sm-1 md-mb-40px xs-mb-30px last-paragraph-no-margin"
        >
          <a
            href="demo-accounting.html"
            class="footer-logo mb-15px d-inline-block"
            ><img src="../assets/logo-aia.png" alt=""
          /></a>
          <p class="w-85 xl-w-95 sm-w-100">
            Rua Forte da Ribeira, 350 - Condominio São Gabriel I, Galpão 5 - Jd:
            São Lourenço São Paulo/SP - CEP 08340-145
          </p>
          <div class="elements-social social-icon-style-02 mt-20px lg-mt-20px">
            <ul class="small-icon light">
              <li>
                <a
                  class="facebook"
                  href="https://www.facebook.com/"
                  target="_blank"
                  ><i class="fa-brands fa-facebook-f"></i
                ></a>
              </li>
              <li>
                <a
                  class="dribbble"
                  href="http://www.dribbble.com"
                  target="_blank"
                  ><i class="fa-brands fa-dribbble"></i
                ></a>
              </li>
              <li>
                <a class="twitter" href="http://www.twitter.com" target="_blank"
                  ><i class="fa-brands fa-twitter"></i
                ></a>
              </li>
              <li>
                <a
                  class="instagram"
                  href="http://www.instagram.com"
                  target="_blank"
                  ><i class="fa-brands fa-instagram"></i
                ></a>
              </li>
            </ul>
          </div>
        </div>
        <!-- end footer column -->
        <!-- start footer column -->

        <!-- end footer column -->
        <!-- start footer column -->

        <!-- end footer column -->
        <!-- start footer column -->
        <div class="col-6 col-lg-4 col-sm-4 xs-mb-30px order-sm-5 offset-lg-4">
          <span class="fs-18 fw-400 d-block text-white mb-5px"
            >Fale conosco</span
          >
          <p class="mb-5px">Precisa de ajuda?</p>
          <a
            href="mailto:contato@aiaembalagens.com.br"
            class="text-white lh-16 d-block mb-15px"
            >contato@aiaembalagens.com.br</a
          >
          <p class="mb-5px">Atendimento ao cliente</p>
          <a href="tel:551120247061" class="text-white lh-16 d-block"
            >(11) 2024-7061</a
          >
          <a
            href="https://api.whatsapp.com/send?1=pt_BR&phone=5511942599985"
            class="text-white lh-16 d-block mt-5"
          >
            (11) 94259-9985 (WhatsApp)</a
          >
        </div>
        <!-- end footer column -->
      </div>
      <div class="row align-items-center fs-16 fw-300">
        <!-- start copyright -->
        <div
          class="col-md-12 last-paragraph-no-margin order-2 order-md-1 text-center"
        >
          <p>&COPY; Copyright 2024 Aia Embalagens</p>
        </div>
        <!-- end copyright -->
        <!-- start footer menu -->

        <!-- end footer menu -->
      </div>
    </div>
  </footer>
  <div class="scroll-progress d-none d-xxl-block">
    <a href="#" class="scroll-top" aria-label="scroll">
      <span class="scroll-text">Scroll</span
      ><span class="scroll-line"><span class="scroll-point"></span></span>
    </a>
  </div>
</template>
<script>
export default {
  name: 'AppFooter',
  data() {
    return {};
  },
};
</script>

<style></style>
